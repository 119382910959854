import React from 'react';
import { Helmet } from 'react-helmet';
import { Container } from 'react-bootstrap';
import Footer from '../components/Footer/Footer';
import { headData } from '../data/data';
import '../style/text-page.scss';

const renderTerms = () => {
  return (
    <>
      <div className="text-container">
        <h1>利用規約</h1>
        <h2>はじめに</h2>
        <p>
          この利用規約（以下、「本規約」と言います。）は、「動画で使える、商用利用可能な効果音や音楽」（以下、「当サイト」と言います）を利用する際の全ての行為について適用されます。
          <br />
          利用者が当サイトを利用する場合は、本規約のすべての項目に同意し、利用契約を締結したものと見なします。
        </p>
        <h2>著作権</h2>
        <p>
          当サイトに掲載している文章・画像・プログラムなどの一部または全てについて、
          無断で、転載や複製、収集、再利用、アップロードなどすることは、著作権法によって禁止されています。
        </p>
        <p>
          また、当サイトで無料で利用可能としている音楽については、別途
          <a href="#音楽に関する利用規約">音楽に関する利用規約</a> で規約を定めています。
        </p>
        <h2>禁止行為</h2>
        <p>
          当サイトに対して、不当にアクセス負荷をかける行為を禁止します。
          <br />
          また、その他、当サイトに損害が出る可能性のある、一切の行為を禁止します。
        </p>
        <h2>損害賠償</h2>
        <p>
          利用者または利用者の関係者が、本規約に違反する行為により当サイトに損害を与えた場合、利用者は当サイトに対しその損害を賠償するものとします。
        </p>
        <h2>免責事項</h2>
        <p>
          当サイトのご利用は自己責任でお願い致します。当サイトのご利用によって何らかの被害や損害を被った場合でも、当サイトは一切責任を負わないものとします。
        </p>
        <p>
          当サイトの利用における、第三者との紛争に関しては、利用者が自らその責任と費用負担において解決するものとし、
          当サイトは一切責任を負わないものとします。
        </p>
        <h2>連絡先</h2>
        <p>
          お問い合わせはこちらのお問い合わせフォームよりご連絡をお願いいたします。
          <br />
          (お問合せフォームはGoogle Formを利用しています)
          <br />
          <span className="d-flex mt-3">
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="cta-btn cta-btn--resume"
              href="https://docs.google.com/forms/d/e/1FAIpQLScIPEqNUyI_93_A64Yw-M92nCQG32TFgKr1-Zkp0vFu6CgJeA/viewform"
              style={{ background: '#00cdac', border: 'solid 1px #00cdac' }}
            >
              問い合わせフォームを開く
            </a>
          </span>
        </p>
        <h2>その他</h2>
        <p>
          本規約は、事前の予告なしに改訂することがあります。
          <br />
          すでに締結された利用契約にも、変更後の本規約を適用できるものとします。
        </p>
        <p>
          本規約に関する紛争について、利用者は誠意をもって協議の上これを解決するものとします。
          <br />
          裁判上の紛争が生じた場合は、東京地方裁判所または東京簡易裁判所を第一審の専属的合意管轄裁判所とします。
        </p>
      </div>
      <div className="text-container">
        <h2 id="音楽に関する利用規約">音楽に関する利用規約</h2>
        <p>
          この音楽に関する利用規約（以下、「本規約」と言います。）は、「動画で使える、商用利用可能な効果音や音楽」（以下、「当サイト」と言います）で公開されているすべての楽曲を利用する際に適用されます。
        </p>
        <p>
          利用者が当サイトの音楽を利用する場合は、本規約のすべての項目に同意し、利用契約を締結したものと見なします。
        </p>
        <h2>利用可能な用途</h2>
        <p>
          当サイトで公開している音楽素材は、商用利用、個人利用問わず利用できます。
          <br />
          具体的には下記の用途で利用可能です。
        </p>
        <ul>
          <li>
            音楽素材は、ウェブ、映像、ゲーム、ラジオなど、何かのBGMとして利用することが可能です。
            <ul>
              <li>
                例として、Youtubeなどの動画制作に使用することが可能です。その際にクレジット表記などは必要ありません。
              </li>
            </ul>
          </li>
          <li>音楽レッスン用の教材として利用することが可能です。</li>
          <li>楽曲のカバーを自身のコンテンツとした商用利用は可能です。</li>
        </ul>
        <h2>禁止事項</h2>
        <p>また以下の行為は禁止しております。</p>
        <ul>
          <li>公序良俗に反する目的での利用は禁止しております。</li>
          <li>楽曲、製作者のイメージを損なうような利用は禁止です。</li>
          <li>楽曲自体をコンテンツ・商品として再配布・販売することは禁止しています。</li>
          <li>自分が作曲したものとして偽る行為は禁止です。</li>
          <li>楽曲を使用した作品を、JASRACなど著作権管理団体に登録することはできません。</li>
          <li>楽曲利用の際に生じたいかなる損害損失に対しても当サイトは一切責任を負いません。</li>
          <li>楽曲を利用することによって発生したトラブルについては一切責任を負いかねます。</li>
        </ul>
        <h2>YouTubeのコンテンツID（Content ID）への登録を禁止しています。</h2>
        <p>当サイトの音楽を使用した作品をYouTubeのコンテンツIDに登録することは禁止しております。</p>
        <p>
          コンテンツID登録をした場合、YouTubeで同じ音楽素材を使用しているユーザに対して著作権保護の警告が出てしまい、当サイトが意図していた音楽の利用が出来なくなってしまいます。
        </p>
        <p>
          コンテンツIDで商品管理をしている委託販売サイトやレーベル、企業も見受けられますので、
          音楽を使用した作品を販売、投稿される際はコンテンツIDを登録されないよう、お願いいたします。
        </p>
        <h2>著作権</h2>
        <p>
          楽曲は<a href="#音楽に関する利用規約">音楽に関する利用規約</a>
          に基づいて、無料、かつ自由に使用可能ですが、著作権は放棄しておりません。
        </p>
        <p>これらの楽曲の著作権はすべて作曲者が所有します。</p>
        <h2>その他</h2>
        <p>
          本規約は、事前の予告なしに改訂することがあります。
          <br />
          すでに締結された利用契約にも、変更後の本規約を適用できるものとします。
        </p>
        <p>
          本規約に関する紛争について、利用者は誠意をもって協議の上これを解決するものとします。
          <br />
          裁判上の紛争が生じた場合は、東京地方裁判所または東京簡易裁判所を第一審の専属的合意管轄裁判所とします。
        </p>
      </div>
    </>
  );
};

export default () => {
  const { title, lang } = headData;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title} | 利用規約</title>
        <html lang={lang || 'en'} />
        <meta name="description" content="利用規約に関するページとなります。" />
      </Helmet>
      <Container>{renderTerms()}</Container>
      <Footer />
    </>
  );
};
